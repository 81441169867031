// Footer
#footer {
  border: none;
  background-color: $navy;
}
.footer {
  overflow: hidden;
}
.footer-top .footer_info_l,
.footer-top .footer_info_r {
  padding: 55px 0;
}
.footer_logo {
	padding: 0 50px 0 0 !important;
	width: 553px;
}
.footer_logo img {
	width:100%;
	max-width: 403px;
  max-height: 76px;
  display: block;
}
.footer .flex-parent {
  padding-top: 0;
  padding-bottom: 0;
}
.footer_info_l {
  width: 77%;
  transition: all 0.4s;
}
.footer_info_r {
  position: relative;
  width: 23%;
  background-color: $dark-navy;
  transition: all 0.4s;

}
.footer_info_r::after {
  content: "";
  position: absolute;
  right: -1000px;
  top: 0;
  height: 100%;
  width: 1000px;
  background-color: $dark-navy;
}

.footer .copyright {
  background-color: $dark-navy;
  position: relative;
  padding: 31px 0;
  line-height: 1.42;
}
.footer .copyright::after {
  content: "";
  position: absolute;
  left: -1000px;
  top: 0;
  height: 100%;
  width: 1000px;
  background-color: $dark-navy;
}
.copyright p {
  margin: 0;
  line-height: 1.42;
  opacity: 0.3;
   font-size: 14px;
   letter-spacing: 0.7px;
   text-align: left;
   color: #ffffff;
}
.footer_pnone {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #f48222;
  color: #fff;
  font-size: 20px;
  line-height: 1.2;
  letter-spacing: 1px;
  text-align: left;
}
.footer_pnone:hover {
  color: #ffffff;
}
.footer_pnone::after {
  content: "";
  position: absolute;
  right: -1000px;
  top: 0;
  height: 100%;
  width: 1000px;
  background-color: #f48222;
  z-index: 1;
}
.footer_pnone_inner {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.flex-nav-footer {
  padding-right: 65px;
  padding-left: 0;
  margin-bottom: 35px;
  //border-bottom: solid 3px rgba(255, 255, 255, .18);
}
.footer_info_p,
.footer .copyright {
  padding-right: 65px;
}
.flex-nav-footer .flex-nav {
  margin: 0;
  padding: 0;
  padding-bottom: 20px;
  border-bottom: solid 3px rgba(255, 255, 255, .18);
	min-height: 76px;
}
.flex-nav-footer .wrapp-flex-ul {
  padding-right: 0;
}
.flex-nav-footer .flex-ul {
  margin-right: -10px;
}
nav.flex-nav-footer ul li {
	padding: 0;
	flex-grow: 1;
	min-width: 25%;
}
nav.flex-nav-footer ul li a {
  opacity: 0.65;
  font-size: 14px;
  line-height: 1.86;
  letter-spacing: 1.4px;
  text-align: left;
  color: #cac9c9;
  text-transform: inherit;
  font-family: $open_sans;
}
nav.flex-nav-footer ul li a:hover {
  opacity: 1;
}

.flex-nav-footer .flex-ul {
	flex-wrap: wrap;
}

.footer_info p {
  opacity: 0.65;
  font-size: 14px;
  line-height: 1.71;
  letter-spacing: 0.7px;
  text-align: left;
  color: #ffffff;
  margin: 0;
}
.footer_info.gray p {
  color: #cac9c9
}

.footer_info_r_inner {
  padding-left: 65px;
}
.footer_social {
  padding-bottom: 20px;
  border-bottom: solid 3px rgba(255,255,255,0.18);
  margin-bottom: 35px;
	min-height: 76px;
}
.footer_social ul.social {
  margin-bottom: 3px;
  margin-top: 20px;
}
.footer_social ul.social li {
  margin: 0;
  transition: all 0.3s;
}
.footer_social ul.social li:hover {
  background-color: #fff;
}
.footer_social ul.social li:hover a svg path,
.footer_social ul.social li:hover a svg mask {
  fill: #151818!important;
}
.footer_social ul.social li:first-child {
  border-right: 1px solid #fff;
}
.footer_social ul.social li a {
  width: 40px;
  height: 40px;
  color: transparent;
  background: transparent;
}
.footer_social [class^='icon-']:before,
.footer_social [class*=' icon-']:before {
  content: none;
}
.footer_social ul.social li a svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.footer_social ul.social li a svg path {
  fill: #fff;
}
.footer,
.wrapp_top_link {
  position: relative;
}
.flex-parent.wrapp_top_link {
  padding: 0!important;
}

.top_link {
  position: absolute;
  right: 16px;
  top: -42.5px;
  width: 85px;
  height: 85px;
  z-index: 11;
  transition: all 0.3s;
}
.top_link:hover {
  top: -85px;
}
.top_link:hover .top_link_arrow {
  background-color: #e0771f;
}
.top_link:hover .top_link_text {
  top: 85px;
  z-index: 2;
}
.top_link:not(:hover) {
  transition-delay: 0.2s;
}
.top_link:not(:hover) .top_link_arrow {
  transition-delay: 0.2s;
}
.top_link:not(:hover) .top_link_text {
  transition-delay: 0.2s;
}
.top_link:not(:hover) .top_link_text_inner {
  opacity: 0;
  top: 60%;
  transition: all 0.2s;
}
.top_link:hover .top_link_text_inner {
  opacity: 1;
  top: 50%;
}
.top_link_arrow {
  position: absolute;
  left: 0;
  top: 0;
  display: block;
  width: 100%;
  height: 85px;
  background-color: #f48222;
  z-index: 2;
  transition: all 0.3s;
}
.top_link_arrow::after {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  width: 22px;
  height: 30px;
  transform: translate(-50%, -50%);
  background-image: url(../img/svg-ico/arrow-top.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
.top_link_text {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 85px;
  background-color: #f48222;
  font-size: 14px;
  letter-spacing: 0.6px;
  text-align: center;
  color: #ffffff;
  z-index: 1;
  transition: all 0.3s;
}
.top_link_text_inner {
  position: absolute;
  left: 50%;
  top: 60%;
  transform: translate(-50%, -50%);
  width: 100%;
  text-align: center;
  padding: 0 10px;
  line-height: 1.42;
  opacity: 0;
  transition: 0.2s all 0.3s;
}
