body {
    background-color:#EEEEEE;
}

html, body {
    margin:0;
}


.white {background-color:#FFFFFF;} /* @FIXME style name */


// Nav?
#nav {background:rgba(0,0,0,0.5);}
nav ul li a {color:white;}

//Fixed-header
.fixed-header {position:fixed;}