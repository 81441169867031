@import 'helpers/fonts';

/***************** FONTS *******************/

$open_sans: 'OpenSansRegular', sans-serif;
$open_sans_light: 'OpenSansLight', sans-serif;
$open_sans_light_italic: 'OpenSansLightItalic', sans-serif;
$open_sans_semi_bold: 'OpenSansSemiBold', sans-serif;
$open_sans_bold: 'OpenSansBold', sans-serif;

/***************** COLORS *******************/

/* Color palette */

// OLD COLOURS FOR LSOS
// /* Color palette */

$dark-slate-blue: #183565;
$dark-slate-blue-two: #1c3a6e;
$dark-sky-blue: #55acee;
$denim-blue: #3b5998;
$nice-blue: #0976b4;
$light-burgundy: #922545;
$pale-red: #dd4b39;
$sunflower-yellow: #ffd602;
$dirty-purple-53: rgba(74, 73, 74, 0.53);
$white: #ffffff;
$white-three: #d8d8d8;
$white-four: #dfdddd;
$blue-grey: #5c767e;
$blue-grey-96: rgba(91, 118, 126, 0.96);
$bluish-grey: #768c93;
$slate-grey: #55565a;
$slate-grey-96: rgba(87, 103, 108, 0.96);
$pale-grey: #ebedf0;
$pale-grey-91: rgba(232, 235, 238, 0.91);
$pale-grey-95: rgba(232, 235, 238, 0.95);
$light-grey: #f8f9f7;
$light-grey-two: #dededd;
$silver: #bdc6cc;
$silver-two: #b6bcb8;
$silver-three: #bcc6cb;
$nasty-green: #7ea43e;

$warm-grey-two: #979797;

$dull-orange: #D17B2F;

// NEW COLOURS FROM ACCOUNTANTS
$petrol-60: rgba(0, 96, 109, 0.6);
$pale-grey: #ecf1f0; //#f1f9f8;
$petrol-45: rgba(0, 96, 109, 0.45);
$petrol: #00606d;
$rouge: #b11b29;
$greyish-brown: #4a4a4a;
$charcoal-grey: #313132;
$white: #faf7f7;
$pale-grey-94: rgba(240, 248, 247, 0.94);
$dark-grey-blue: #2c585e;
$silver: #b6bcb8;
$peacock-blue: #005e9c;
$white-two: #dedddd;
$dark-grey-blue-94: rgba(44, 88, 94, 0.94);
$rouge-two: #b01b29;
$warm-grey: #979797;
$white-three: #ffffff;
$black: #000000;

// Main Colours
$accent-primary: $rouge;
$accent-secondary: $petrol;
$link-primary: $rouge;
$hover-primary: $greyish-brown;

// global Styles
$background-grey: rgba($pale-grey,.7);
$body-text: $greyish-brown;

// Section Colours

// Left header style
$a_background: rgba($dark-grey-blue,.94);
$a_text: $white;
$a_accent: $accent-primary;
$a_other: $white-three;

// Right hedaer style
$b_background: $pale-grey-94;
$b_text: $greyish-brown;
$b_accent: $accent-secondary;
$b_other: transparent;

//Sidebar widget Style
$side_background: $pale-grey;
$side_background_b: $pale-grey;
$side_text_b: $greyish-brown;
$side_border: $dark-grey-blue;

// BUTTONS
// btn_a
$a_btn-border: $accent-primary;
$a_btn-text: $white-three;
$a_btn-background: $accent-primary;

$a_btn-hover-border: $accent-primary;
$a_btn-hover-text: $white-three;
$a_btn-hover-background: $charcoal-grey;

// btn_b
$b_btn-border: $a_btn-border;
$b_btn-text: $a_btn-text;
$b_btn-background: $a_btn-background;

$b_btn-hover-border: $a_btn-border;
$b_btn-hover-text: $a_btn-text;
$b_btn-hover-background: $a_btn-background;

//Content types
$newstype_news: $peacock_blue;
$newstype_event: $nasty-green;
$newstype_guidance: $dull-orange;
$newstype_cpd: $link-primary;
$newstype_proposalform: $dark-slate-blue-two;

// Products and s4rvices
$insurance_product: $accent-primary;
$insurance_service: $accent-secondary;





/******************* MATT NEW *************************/
/* dunno what form the above we want to keep. Looks like this was a copy of LSoS at some point
Putting all my new variables below for now
 */
//colours
$navy: #102B3F;
$dark-navy: #0C2131;
$orange: #F48222;
$dull-orange: #E0771F;
$bright-orange: #FC6702;




/***************** MEDIA QUERIES**************/

$breakpoints: (
  'phoneport'	: 320px,
  'phoneland'	: 568px,
  'tabport'		: 768px,
  'wide-tab'	: 991px,
  'desktop'		: 1024px
);
