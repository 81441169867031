@import 'variables';

/* Large Devices */
@media only screen and (max-width : 1199px) {
  #nav nav ul li {
    padding: 0 17px;
  }
  .wrapp-flex-ul {
    padding-right: 0;
  }
  .advantages-desc {
    padding-left: 60px;
    padding-top: 60px;
  }
  .advantages .advantages-list {
    padding-right: 30px;
  }
  .our_services-title span {
    padding: 0 12px 0 15px;
  }
  .our_services-item {
    padding: 0 10px;
  }
  .our_services .our_services-list {
    margin-left: -26px;
    margin-right: -26px;
  }
  .our_services-desc {
    padding: 0 12px 0 15px;
  }
  .home_w2 .col-6 {
    width: 100%;
  }
  .home_w2 .news_list {
    margin-left: -16px;
    margin-right: -16px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
  }
  .home_w2 .news_item {
    width: 50%;
    padding: 0 16px;
  }
  .home_w2 .col-6 {
    margin-bottom: 25px;
  }
  .home_w2 .col-6.social_media_wrapp {
    margin-bottom: 50px;
  }
  .flex-nav-footer .wrapp-flex-ul {
    display: none;
  }
  .footer .footer_info_r_inner {
    padding-left: 20px;
  }
  .footer .flex-nav-footer {
    padding-right: 20px;
  }
  .footer .footer_info_p,
  #footer .footer .copyright {
    padding-right: 20px;
  }
  .wrapp_lastet_news .news_item_inner {
    border-bottom: none;
  }
  .page_banner-info {
    right: 16px;
  }
  .page_banner {
    height: 600px;
  }
  .blockquote_left_padding {
    padding-left: 60px;
  }
}

@media only screen and (max-width : 1024px) {
  .footer .footer_info_r {
    width: 35%;
  }
  .footer .footer_info_l {
    width: 65%;
  }
  .blockquote_left_padding {
    padding-left: 0px;
  }
  .contact_form {
    padding-left: 0;
  }
}

/* Medium Devices, Desktops */
@media only screen and (max-width : 991px) {
  .block {
    padding: 35px 0;
  }
  .col-6 {
    width: 100%;
  }
  .container {
    max-width: 822px;
    margin: 0 auto;
  }
  .news_item {
    margin-bottom: 17px;
  }
  .btn-more .btn-more_text {
    width: 132px;
  }
  .btn-more .btn-more_arrow {
    background-color: #e0771f;
  }
  .our_services {
    max-width: 852px;
    margin: 0 auto;
  }
  .advantages-desc-inner {
    max-width: 790px;
    margin: 0 auto;
  }
  .advantages .advantages-list {
    max-width: 822px;
    margin: 0 auto;
  }
  #nav nav ul li {
    padding: 0 7px;
  }
  .latest_news-name {
    padding-left: 180px;
    padding-right: 200px;
  }
  .latest_news-read_more-text {
    left: 70px;
    max-height: 78px;
  }
  .advantages .advantages-list {
    width: 100%;
    padding: 80px 16px 89px 16px;
  }
  .advantages-desc {
    width: 100%;
    padding: 83px 16px 87px 16px;
  }
  .advantages-list, .advantages-desc {
    min-height: 100%;
  }
  .advantages .bounds.flex-parent {
    padding: 0;
  }
  .advantages-desc p {
    padding-bottom: 0;
  }
  .advantages-desc::after {
    content: none;
  }
  .our_services-item {
    width: 50%;
    padding: 0 31px;
  }
  .our_services .our_services-list {
    margin-left: -47px;
    margin-right: -47px;
  }
  .our_services-more_text {
    width: 132px;
  }
  .our_services-more_arrow {
    background-color: #e0771f;
  }
  .our_services-border {
    height: 361px;
  }
  .news_item,
  .home_w2 .news_item {
    width: 100%;
  }
  #footer .top_link {
    width: 71px;
    height: 71px;
    top: -35.5px;
  }
  #footer .top_link_arrow {
    height: 71px;
  }
  #footer .top_link_text {
    top: -25px;
    opacity: 1;
    height: inherit;
    background: transparent;
  }
  #footer .top_link_text_inner {
    height: inherit;
    color: #323838;
    top: 0;
    left: 0;
    font-size: 12px;
    background: transparent;
    z-index: 11;
    transform: none;
    height: inherit;
    padding: 0;
    opacity: 1;
  }
  html #home #main {
    //padding-bottom: 30px;
  }
  .page_banner-info {
    padding-bottom: 71px;
  }
  .page_banner-info_link {
    height: 71px;
    height: 71px;
  }
  .page_banner-info_link_arrow,
  .page_banner-info_link {
    width: 71px;
  }
  .page_banner-info_link_text {
    line-height: 71px;
  }
  .page_banner {
    height: 500px;
  }

  .page_banner-info_link .page_banner-info_link_text {
    width: 156px;
    right: -156px;
  }
  .page_banner-info_link {
    right: 156px;
  }
  .page_banner-info_link .page_banner-info_link_arrow {
    background-color: #e0771f;
    overflow: inherit;
  }
  .page_banner-info_link .page_banner-info_link_text_inner {
    opacity: 1;
    padding-left: 0;
  }

  .bottom_link {
    display: none;
  }

  .text_fz28, .text_fz28 p {
    font-size: 22px;
    line-height: 1.42;
  }
  .wrapp_map {
    padding-top: 0;
  }
  .last_block_main {
    padding-bottom: 100px;
  }
  body .blockquote_fz28 blockquote {
    font-size: 22px;
    margin-bottom: 0;
  }
  .carusel_latest_news {
    width: 100%!important;
  }
  .flex-parent.single-news-flex-parent-reverse {
    flex-direction: column-reverse;
  }
  .block img.single-news-img {
    margin-bottom: 50px;
  }
  .btn-more-arr-text .btn-more_nav_text {
    opacity: 1;
    visibility: visible;
    color: #323838;
  }
  .carousel.wrapp_carusel_latest_news a[href^='#carousel-button']:first-child {
    border: none;
  }
  .wrapp_carusel_latest_news .carusel_latest_news .news_item {
    padding-right: 0px;
  }
  .text_column_2 {
    column-count: 1;
  }

}

/* Small Devices, Tablets */
@media only screen and (max-width : 768px) {
  #menu-button-mobile {
    position: relative;
    -ms-flex: 0 0 auto;
    -webkit-box-flex: 0;
    flex: 0 0 auto;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
    width: 48px;
    height: 48px;
    position: relative;
    cursor: pointer;
  }
  #menu-button-mobile span {
    font-size: 0;
    line-height: 0;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    padding: 0;
    color: #323838;
  }
  #menu-button-mobile>span::before,
  #menu-button-mobile>span::after {
    position: absolute;
    left: 50%;
    width: 30px;
    height: 4px;
    margin-left: -15px;
    content: '';
    transition-timing-function: cubic-bezier(0.445, 0.05, 0.55, 0.95);
    transition-duration: 200ms;
    -webkit-transform: translate3d(0px, 0px, 0px);
    transform: translate3d(0px, 0px, 0px);
    background: #323838;
  }
  #nav #menu-button-mobile {
    margin-right: 0px;
  }
  #nav #menu-button-mobile span span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #323838;
  }
  #nav #menu-button-mobile>span:before {
    top: 12px;
  }
  #nav #menu-button-mobile>span:after {
    bottom: 12px;
  }
  #nav #menu-button-mobile.close>span:before {
    top: 23px;
  }
  #nav #menu-button-mobile.close>span:after {
    bottom: 23px;
  }
  #nav #menu-button-mobile span span,
  #nav #menu-button-mobile>span:before,
  #nav #menu-button-mobile>span:after {
    height: 2px;
    width: 36px;
  }
  #nav #menu-button-mobile>span:before,
  #nav #menu-button-mobile>span:after {
    margin-left: -18px;
  }
  #nav nav ul li {
    padding: 0;
  }
	#nav nav ul li a:hover {
		color: $bright-orange;
	}
  #nav .flex-ul {
    box-shadow: none;
  }

  .wrapp-flex-ul {
    display: block;
    position: fixed;
    right: -100%;
    top: 0;
    width: 100%;
    height: 100%;
    min-height: 100%;
    background-color: $dark-navy;
    z-index: 1111111111;
    padding-right: 25%;
    transition: right 0.4s
  }
  .wrapp-flex-ul.open {
    right: 0;
  }
  .wrapp-flex-ul::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 75%;
    height: 100%;
    background: $navy;
    z-index: 0;
  }
  .wrapp-flex-ul .flex-ul {
    display: block;
    z-index: 11;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    padding-bottom: 160px;
    padding-left: 78px;
    position: relative;
    background: transparent;
  }
  .wrapp-flex-ul .flex-ul li {
    float: none;
  }
  #nav nav ul li a {
    display: block;
    font-size: 24px;
    color: #fff;
    padding-left: 12px;
    margin-bottom: 15px;
    opacity: 0;
    transition: all 0.5s;
  }
  #nav nav ul li a.visible {
    opacity: 1;
    padding-left: 0;
  }
  #nav nav ul li a::after {
    content: none;
  }
  .wrapp-flex-ul-footer {
    display: block;
    position: absolute;
    bottom: 20px;
    left: 78px;
    z-index: 11;
	  max-width: 50%;
  }
  .wrapp-flex-ul-footer-logo {
    position: relative;
    padding-bottom: 20px;
    margin-bottom: 24px;
  }
	
  .wrapp-flex-ul-footer-logo::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 3px;
    background-color: #fff;
    opacity: 0.18;
  }
  .wrapp-flex-ul-footer-desc {
    max-width: 299px;
    opacity: 0.3;
    font-family: $open_sans;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.71;
    letter-spacing: 0.7px;
    text-align: left;
    color: #ffffff;
  }
  .wrapp-flex-ul-footer-desc p {
    margin: 0;
  }
  .wrapp-flex-ul-close {
    display: block;
    position: absolute;
    top: 55px;
    right: 41px;
    width: 25px;
    height: 25px;
    padding: 0;
  }
  .wrapp-flex-ul-close::after,
  .wrapp-flex-ul-close::before {
    content: "";
    position: absolute;
    left: -2px;
    top: 50%;
    width: 30px;
    height: 2px;
    margin-top: -1px;
    background-color: #fff;
  }
  .wrapp-flex-ul-close::after {
    transform: rotate(45deg);
  }
  .wrapp-flex-ul-close::before {
    transform: rotate(-45deg);
  }
  /*.latest_news-read_more {
    right: -812px;
  }*/
  .latest_news-name {
    font-size: 15px;
    letter-spacing: 0.7px;
    line-height: 1.42;
    padding-left: 151px;
    padding-right: 210px;
  }
  .latest_news-title {
    font-size: 15px;
  }
  .latest_news-read_more::after, .latest_news-read_more::before {
    left: 24px;
  }
  .latest_news-read_more-text {
    left: 64px;
  }
  .our_services-desc {
    height: 114px;
  }
  .our_services-border {
    height: 420px;
  }
  .our_services {
    max-width: 695px;
    margin: 0 auto;
  }
  .container {
    max-width: 631px;
  }
  .advantages-desc,
  .advantages .advantages-list {
    padding: 80px 16px 87px 16px;
  }
  .advantages-desc-inner,
  .advantages .advantages-list {
    max-width: 631px;
    margin: 0 auto;
  }
  .advantages .advantages-list {
    max-width: 663px;
  }
  ul.ul {
    padding-left: 4px;
  }
  .footer .footer_pnone {
    font-size: 19px;
  }
  .page_banner .title-wrap {
    /*display: none;*/
  }
  .page_banner-info_desc {
    font-size: 23px;
    letter-spacing: 1px;
    line-height: 1.42;
  }
  .page_banner-info {
    width: 470px;
  }
  .page_banner-info_inner {
    padding: 47px 43px 44px 50px;
  }
  .page_banner-info_link_text {
    font-size: 15px;
  }
  .page_banner-info_link_arrow::after {
    width: 20px;
  }
  .page_banner {
    height: 438px;
  }
  .pagination {
    padding-right: 0;
  }
  .wrapper_news {
    padding-bottom: 90px;
  }
	
	.flex-nav .logo {
		max-width: calc(100% - 60px);
	}
	
	#footer .footer_logo {
		width: 100%;
		padding: 0!important;
	}
	.footer_logo svg {
		max-width: 100%;
	}

}

@media only screen and (max-width : 599px) {
  .flex-parent.row-m {
    margin: 0;
  }
  .latest_news-read_more {
    right: -880px;
  }
  .page_banner_inner::after {
    content: none;
  }
  .latest_news-read_more-text {
    left: 60px;
    width: 50px;
    line-height: 1.2;
  }
  .latest_news-name {
    padding-right: 140px;
  }
  .advantages .advantages-list {
    padding: 50px 16px;
  }
  .advantages-desc {
    padding: 44px 16px 54px;
  }
  .our_services-item {
    padding: 0 16px;
  }
  .our_services .our_services-list {
    margin-left: -16px;
    margin-right: -16px;
    padding: 0;
  }
  .our_services {
    padding-left: 0;
    padding-right: 0;
  }
  .title-link {
    float: right;
  }
  .title-wrap-link {
    display: block;
  }
  .footer .footer_info_l,
  .footer .footer_info_r {
    width: 100%;
    background: transparent;
  }
  #footer .footer {
    position: relative;
  }
  #footer .footer .footer_info_r {
    padding-top: 0;
  }
  #footer .footer .footer_info_l {
    padding-bottom: 0;
  }
  #footer .footer .footer_info_r_top {
    position: static;
  }
  .footer .footer_info_r::after {
    content: none;
  }
  .footer .footer_info_r_inner {
    padding-left: 0;
  }
  .footer .footer_social {
    position: absolute;
    right: 16px;
    top: 43px;
    padding: 0;
    margin: 0;
    border: none;
  }
  .footer .footer-top .footer_info_l {
    padding-top: 36px;
  }
  .footer .flex-nav-footer {
    padding-right: 8px;
    padding-left: 8px;
  }
  .footer .footer_info_p,
  .footer .footer_info,
  #footer .footer .copyright {
    padding-left: 8px;
    padding-right: 8px;
    font-size: 13px;
  }
  .footer .footer_info.gray {
    margin-bottom: 5px;
    display: none;
  }
  .footer .flex-nav-footer {
    margin-bottom: 25px;
  }
  .wrapp_footer_pnone {
    height: 89px;
  }
  .wrapp_footer_pnone .footer_pnone::after {
    right: -300px;
  }
  .wrapp_footer_pnone .footer_pnone_inner {
    z-index: 2;
  }
  .footer .footer-bottom {
    flex-direction: column-reverse;
  }
  #footer .footer .copyright::after {
    left: -300px;
  }
  #footer .footer .copyright p {
    position: relative;
    z-index: 2;
    font-size: 12px;
  }
  #footer .footer .copyright {
    padding: 24px 0;
  }
  .footer .footer_info p {
    opacity: 0.65;
    font-size: 13px;
    line-height: 1.69;
    letter-spacing: 0.6px;
    text-align: left;
    color: #ffffff;
    margin-bottom: 15px;
  }
	.col-4, .col-8, .col-5, .col-7 {
		width: 100%;
	}
}

/* Extra Small Devices, Phones */
@media only screen and (max-width : 568px) {
  #nav .flex-nav {
    padding: 0 16px;
  }
  body header#nav {
    padding: 12px 0 11px 0;
  }
  #nav nav ul li a {
    font-size: 19px;
  }
  /*.wrapp-flex-ul {
    padding-right: 84px;
  }*/
  .wrapp-flex-ul .flex-ul {
    padding-left: 40px;
  }
  .wrapp-flex-ul-footer {
    display: none;
  }
  .wrapp-flex-ul .flex-ul {
    padding-bottom: 0;
  }
  .latest_news-name {
    position: relative;
    right: inherit;
    top: inherit;
    transform: none;
    padding-left: 0;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-bottom: 5px;
  }
  .latest_news-title {
    padding: 5px 0;
  }
  .flex-parent.row-inn {
    padding: 0 8px;
  }
  .flex-parent.row-inn {
    margin-left: -8px;
    margin-right: -8px;
  }
  .home_w2 .flex-parent.row-inn {
    margin-left: -16px;
    margin-right: -16px;
  }
  #footer .footer .copyright {
    padding-left: 8px;
    padding-right: 8px;
  }
  .page_banner-info_inner {
    padding: 21px;
    font-size: 19px;
  }
  .page_banner-info_desc {
    font-size: 19px;
  }
  .page_banner-info {
    right: 0;
    width: 100%;
    padding-left: 16px;
  }
  .page_banner-info_link .page_banner-info_link_text {
    width: 119px;
    right: -119px;
  }
  .page_banner-info_link {
    right: 119px;
  }
  .page_banner-info_link_text {
    font-size: 12.5px;
  }
  .page_banner-info_link_arrow::after {
    width: 18px;
  }
  .page_banner-info_link {
    height: 64px;
    height: 64px;
  }
  .page_banner-info_link_arrow, .page_banner-info_link {
    width: 64px;
  }
  .page_banner-info {
    padding-bottom: 64px;
  }
  .page_banner-info_link_text {
    line-height: 64px;
  }
  .logo_list, .logo_list.align-right {
    justify-content: center;
  }
  .block .col-6 {
    padding: 0 8px;
  }

  .wrapp-btn-more,
  .wrapp_our_services-more {
    height: 64px;
  }
  .btn-more_text,
  .our_services-more_text {
    line-height: 64px;
    font-size: 12.5px;
  }
  .btn-more_arrow,
  .our_services-more_arrow {
    width: 65px;
  }
  .btn-more .btn-more_text,
  .btn-more:hover .btn-more_text,
  .our_services-more_text,
  .our_services-more:hover .our_services-more_text {
    width: 120px;
  }
  .btn-more_arrow::after,
  .our_services-more_arrow::after {
    width: 18px;
  }
  .col-12 {
    padding-left: 8px;
    padding-right: 8px;
  }
  .wrapper_news {
    padding-bottom: 90px;
  }
  .btn-more-arr-text .btn-more_arrow::after {
    width: 24px;
  }
  .accordeon_woodfood_trigger {
    font-size: 16px;
  }
  .home_w2 .news_list {
    margin-left: 0;
    margin-right: 0;
    display: block;
    flex-wrap: initial;
    flex-direction: initial;
  }
  .home_w2 .news_item {
    width: 100%;
    padding: 0;
    box-sizing:border-box;
  }
  .btn-more_nav_text{
    bottom:-30px;
  }
}

/* Extra Small Devices, Phones */
@media only screen and (max-width : 479px) {
  .flex-nav .logo a {
    /*height: 55px;*/
  }
  #nav #menu-button span span,
  #nav #menu-button>span:before,
  #nav #menu-button>span:after {
    width: 30px;
  }
  #nav #menu-button>span:before,
  #nav #menu-button>span:after {
    margin-left: -15px;
  }
  #nav #menu-button {
    margin-right: -8px;
  }
  .wrapp-flex-ul::after {
    width: 74%;
  }
  .wrapp-flex-ul {
    padding-right: 26%;
  }
  #nav nav ul li a {
    margin-bottom: 12px;
  }
  .wrapp-flex-ul-close::after,
  .wrapp-flex-ul-close::before {
    content: "";
    left: -2px;
    top: 50%;
    width: 24px;
    height: 2px;
  }
  .wrapp-flex-ul-close {
    top: 30px;
    right: 21px;
    width: 20px;
    height: 20px;
  }
  .flex-nav .logo a img.logo-img-mobile {
    display: block;
  }
  .flex-nav .logo a img.logo-img {
    display: none;
  }
  .latest_news-read_more {
    right: -916px;
  }
  .latest_news-read_more::after, .latest_news-read_more::before {
    width: 12px;
    left: 15px;
  }
  .latest_news-read_more-text {
    left: 40px;
    font-size: 11px;
    line-height: 1.4;
    width: 32px;
  }
  .latest_news-title {
    font-size: 12px;
    padding: 4px 0 2px;
  }
  .latest_news-name {
    font-size: 12px;
    padding-right: 105px;
    margin-bottom: 3px;
  }
  .advantages-item {
    margin-bottom: 22px;
    min-width: 98px;
  }
  .advantages .advantages-list {
    padding-top: 40px;
    padding-bottom: 18px;
    justify-content: center;
  }
  .advantages-desc {
    padding: 44px 16px 54px;
    font-size: 19px;
  }
  .advantages-desc p {
    font-size: 19px;
  }
  #nav #menu-button-mobile span span, #nav #menu-button-mobile>span:before, #nav #menu-button-mobile>span:after {
    width: 30px;
  }
  #nav #menu-button-mobile>span:before, #nav #menu-button-mobile>span:after {
    margin-left: -15px;
  }
  .advantages-item_title {
    font-size: 13px;
  }
  .our_services-item {
    width: 100%;
  }
  .our_services-border {
    height: inherit;
  }
  .our_services-desc {
    height: inherit;
    max-height: 114px;
    margin-bottom: 20px;
    overflow: hidden;
  }
  .title-wrap {
    margin-bottom: 35px;
  }
  .title-wrap h1, .title-wrap h2, .title-wrap h3, .title-wrap h4 {
    font-size: 30px;
    //margin-bottom: 35px;
    line-height: 1.2;
  }
  .our_services {
    padding-top: 43px;
  }
  .advantages .advantages-list {
    padding: 50px 11px
  }
  ul.ul li {
    font-size: 15px;
    padding-left: 30px;
  }
  ul.ul li::before {
    width: 10px;
    height: 10px;
    top: 8px;
  }
  .core_values {
    padding-bottom: 15px;
  }
  .social_media_header {
    display: block;
  }
  .social_media_title {
    color: #f48222;
  }
  .carousel-lastet_news {
    display: block;
  }
  .lastet_news {
    display: none;
  }
  .carousel a[href^='#carousel-button'] {
    width: 64px;
    height: 64px;
  }
  #footer .footer_social ul.social li {
    background-color: #fff;
  }
  #footer .footer .footer_social ul.social li a svg path {
    fill: #151818!important;
  }
  #footer .footer_social ul.social li:last-child {
    border-left: 1px solid #151818;
  }
/*  #footer .footer_logo {
    width: 61px;
    height: 57px;
  }
  #footer .footer_logo img {
    max-width: 61px;
    max-height: 57px;
  }*/


  .footer .footer_social ul.social {
    margin-top: 0;
  }
  .news_more {
    color: #323838;
  }
  .title-link {
    color: #323838;
  }
  .news_more::after {
    background-image: url(../img/svg-ico/small-arrow-right-orange.svg);
  }
  .title-link::after {
    background-image: url(../img/svg-ico/small-arrow-right-grey-hover.svg);
  }
  #footer .top_link {
    top: -44px;
  }
  .flex-nav .logo a img.woodford-logo {
    display: none;
  }
  body blockquote,
  .text_fz16, .text_fz16 p {
    font-size: 15px;
  }
  .text_fz28, .text_fz28 p {
    font-size: 19px;
  }
  body .blockquote_fz28 blockquote {
    font-size: 19px;
  }
  .carusel-news_list .news_info {
    transform: none;
  }
  .news_item {
    margin-bottom: 11px;
  }
  .news_img {
    width: 100%;
  }
  .wrapper_news .news_item {
    height: 100%;
  }
  .news_img {
    height: 195px;
  }
  .news_info {
    position: relative;
    top: inherit;
    right: inherit;
    transform: none;
    width: 100%;
  }
  .block img.single-news-img {
    margin-bottom: 38px;
  }
  .wrapp_carusel_latest_news .carousel-button-wrapper {
    width: 100%;
    text-align: right;
  }
  .block.wrapper_carusel_latest_news {
    padding-top: 0;
  }
  .wrapper_carusel_latest_news .flex-parent {
    padding-top: 0;
  }
  .carousel.wrapp_carusel_latest_news a[href^='#carousel-button']:first-child,
  .carousel.wrapp_carusel_latest_news a[href^='#carousel-button']:nth-child(2),
  .btn-more-arrow-left {
    border: none;
  }
  .news_item {
    height: inherit;
  }
  .accordeon_woodfood_trigger {
    font-size: 14px;
    padding-left: 20px;
    padding-right: 60px;
  }
  .accordeon_woodfood_inner {
    padding: 20px;
  }
  .title-wrap.mobile-visible {
    display: block;
  }
  .people_list {
    display: none;
  }
  .accordeon_woodfood_people {
    display: block;
    .people_item_desc {
      max-height: 100%;
    }
    .people_item_info {
      padding: 20px;
      .people_item_name {
        display: none;
      }
      .people_item_job {
        display: none;
      }
    }
    .wrapp_btn_border_top {
      margin-bottom: 10px;
      margin-top: 40px;
    }
    .people_item_inner {
      border: none;
    }
    .accordeon_woodfood_inner {
      padding-left: 0;
      padding-right: 0;
      padding-top: 0;
      margin-top: -2px;
    }
  }
  /*.people_item {
    max-width: 320px;
    margin: 0 auto 20px;
  }*/
  .people_item_img {
    width: 100%;
    height: 284px;
  }
  .people_item_inner {
    height: inherit;
  }
  .people_item_info {
    width: 100%;
  }
  .mobile-none {
    display: none!important;
  }

  .services_item_inner {
    height: 100%;
  }
  .services_item_img {
    width: 100%;
    height: 211px;
  }
  .services_item_info {
    width: 100%;
    padding: 23px 24px;
  }

}

@media only screen and (max-width : 320px) {

}

@media only screen and (max-width : 767px) and (max-height : 600px) {
  .wrapp-flex-ul-footer {
    display: none;
  }
  .wrapp-flex-ul .flex-ul {
    padding-bottom: 0;
  }
}

@media only screen and (min-width: 1030px) {
	.news_list.flex-parent {
		padding:0;
		margin: -16px -16px 0;
	}
	.news_list.flex-parent .block {
		-webkit-flex: 1 1 47%;
	}
	
	nav.flex-nav-footer ul li {
		min-width: 22%!important;
	}
	
	nav.flex-nav-footer ul li:nth-child(1),
	nav.flex-nav-footer ul li:nth-child(5){
		min-width: 27%!important;
	}
	nav.flex-nav-footer ul li:nth-child(2),
	nav.flex-nav-footer ul li:nth-child(6){
		min-width: 29%!important;
	}
}