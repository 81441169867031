@font-face {
	font-family: 'stunnfont';
	font-weight: normal;
	font-style: normal;

	src: url('../fonts/stunnfont.eot?ja7fha');
	src: url('../fonts/stunnfont.eot?ja7fha#iefix') format('embedded-opentype'),
	url('../fonts/stunnfont.ttf?ja7fha') format('truetype'),
	url('../fonts/stunnfont.woff?ja7fha') format('woff'),
	url('../fonts/stunnfont.svg?ja7fha#stunnfont') format('svg');
}

@font-face {
	font-family: "OpenSansRegular";
	src: url("../fonts/OpenSansRegular/OpenSansRegular.eot");
	src: url("../fonts/OpenSansRegular/OpenSansRegular.eot?#iefix")format("embedded-opentype"),
	url("../fonts/OpenSansRegular/OpenSansRegular.woff") format("woff"),
	url("../fonts/OpenSansRegular/OpenSansRegular.ttf") format("truetype");
	font-style: normal;
	font-weight: normal;
}

@font-face {
	font-family: "OpenSansLight";
	src: url("../fonts/OpenSansLight/OpenSansLight.eot");
	src: url("../fonts/OpenSansLight/OpenSansLight.eot?#iefix")format("embedded-opentype"),
	url("../fonts/OpenSansLight/OpenSansLight.woff") format("woff"),
	url("../fonts/OpenSansLight/OpenSansLight.ttf") format("truetype");
	font-style: normal;
	font-weight: normal;
}

@font-face {
	font-family: "OpenSansLightItalic";
	src: url("../fonts/OpenSansLightItalic/OpenSansLightItalic.eot");
	src: url("../fonts/OpenSansLightItalic/OpenSansLightItalic.eot?#iefix")format("embedded-opentype"),
	url("../fonts/OpenSansLightItalic/OpenSansLightItalic.woff") format("woff"),
	url("../fonts/OpenSansLightItalic/OpenSansLightItalic.ttf") format("truetype");
	font-style: normal;
	font-weight: normal;
}

@font-face {
	font-family: "OpenSansSemiBold";
	src: url("../fonts/OpenSansSemiBold/OpenSansSemiBold.eot");
	src: url("../fonts/OpenSansSemiBold/OpenSansSemiBold.eot?#iefix")format("embedded-opentype"),
	url("../fonts/OpenSansSemiBold/OpenSansSemiBold.woff") format("woff"),
	url("../fonts/OpenSansSemiBold/OpenSansSemiBold.ttf") format("truetype");
	font-style: normal;
	font-weight: normal;
}
@font-face {
	font-family: "OpenSansBold";
	src: url("../fonts/OpenSansBold/OpenSansBold.eot");
	src: url("../fonts/OpenSansBold/OpenSansBold.eot?#iefix")format("embedded-opentype"),
	url("../fonts/OpenSansBold/OpenSansBold.woff") format("woff"),
	url("../fonts/OpenSansBold/OpenSansBold.ttf") format("truetype");
	font-style: normal;
	font-weight: normal;
}

[class^='icon-']:before,
[class*=' icon-']:before,
a[href^='#carousel-button']:after,
#testimonialsCarousel li .testimonial:before,
#testimonialsCarousel li .testimonial:after,
#banner-wrap .flexSlider-nextButton:after,
#banner-wrap .flexSlider-prevButton:after,
.team-title .email:after {
	/* use !important to prevent issues with browser extensions that change fonts */
	font-family: 'stunnfont' !important;
	font-size: 32px;
	font-weight: normal;
	font-style: normal;
	font-variant: normal;
	line-height: 32px;

	position: absolute;

	display: block;

	width: 32px;
	height: 32px;

	content: '';
	-webkit-transition: color .2s ease-in;
	   -moz-transition: color .2s ease-in;
	     -o-transition: color .2s ease-in;
	        transition: color .2s ease-in;
	text-transform: none;

	color: white;

	speak: none;
	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}


.icon-mail:before,
.team-title .email:after {
	content: '\e902';
	color: #d1d3d4;
}

.team-title .email:hover:after {
	color: #c13139;
}


.icon-next:before,
a[href^='#carousel-button-next']:after,
#banner-wrap .flexSlider-nextButton:after {
	content: '\e903';
}
.icon-prev:before,
a[href^='#carousel-button-prev']:after,
#banner-wrap .flexSlider-prevButton:after {
	content: '\e904';
}
.icon-quote-end:before,
#testimonialsCarousel li .testimonial:after {
	content: '\e905';
}
.icon-quote-start:before,
#testimonialsCarousel li .testimonial:before {
	content: '\e906';
}
.icon-twitter:before {
	content: '\e900';
}
.icon-facebook:before {
	content: '\e901';
}

// This should be base?
body {
    font: 16px/26px Helvetica, Helvetica Neue, Arial;
}

// Typography?
h1,h2,h3,h4,h5{
    margin:.5em;
}
