.icon-drop-down-menu {
	width: 536px;
	height: 166px;
}

.icon-geo-icon-colour {
	width: 43px;
	height: 44px;
}

.icon-highway-icon-colour {
	width: 54px;
	height: 47px;
}

.icon-p-j-a-logo-footer {
	width: 181px;
	height: 28px;
}

.icon-p-j-a-logo-header {
	width: 224px;
	height: 35px;
}

.icon-place {
	width: 10px;
	height: 14px;
}

.icon-site-icon-colour {
	width: 37px;
	height: 41px;
}

.icon-water-icon-colour {
	width: 50px;
	height: 38px;
}

