
// This should be base?
html body {
    font: 16px/26px $open_sans;
    background-color: #EEEEEE;
}

html, body {
    margin:0;
}

// Typography?
h1,h2,h3,h4,h5{
    margin:.5em;
}

hr {
  border-top: 1px solid #cac9c9;
}

body strong, body b {
  font-family: $open_sans_semi_bold;
  font-weight: normal;
}

.white {background-color:#FFFFFF;} /* @FIXME shit style name */

ul.ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
ul.ul li {
  position: relative;
  margin-bottom: 31px;
  padding-left: 41px;
  font-family: $open_sans_light;
  font-size: 17px;
  letter-spacing: 0.5px;
  text-align: left;
  color: #4a4a4a;
}
ul.ul li::before {
  content: "";
  position: absolute;
  left: 0;
  top: 7px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #fc7f02;
}

body blockquote {
  position: relative;
  font-family: $open_sans_light;
  font-size: 16px;
  letter-spacing: 0.6px;
  text-align: left;
  color: #4a4a4a;
  margin: 0;
  padding: 0;
  padding-top: 62px;
  padding-bottom: 35px;
  margin-bottom: 35px;
  border-bottom: solid 2px #cac9c9;
}
body blockquote::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 53px;
  height: 42px;
  background-image: url("../img/svg-ico/quote-marks.svg");
  background-repeat: no-repeat;
  background-size: contain;
}

body .blockquote_fz28 blockquote {
  font-size: 28px;
  font-family: $open_sans_light_italic;
  border: none;
  line-height: 1.4;
  letter-spacing: 1.2px;
  text-align: left;
  color: #9b9b9b;
}
.blockquote_left_padding {
  padding-left: 110px;
}

body input[type="text"],
body input[type="email"],
body input[type="password"],
body input[type="tel"] {
  height: 60px;
  box-shadow: inset 1px 1px 3px 0 #ccd1d9;
  border: solid 1px #cac9c9;
  margin-bottom: 20px;
  padding-left: 23px;
  padding-right: 15px;
  width: 100%;
}
body textarea {
  box-shadow: inset 1px 1px 3px 0 #ccd1d9;
  border: solid 1px #cac9c9;
  margin-bottom: 20px;
  padding-left: 23px;
  padding-top: 20px;
  width: 100%;
  resize: none;
}

body *::-webkit-input-placeholder {
  color: #cac9c9;
	opacity: 1;
}
body *:-moz-placeholder {
  color: #cac9c9;
	opacity: 1;
}
body *::-moz-placeholder {
  color: #cac9c9;
	opacity: 1;
}
body *:-ms-input-placeholder {
  color: #cac9c9;
	opacity: 1;
}

// Nav?
body #nav {background:#fff;}
body header#nav {
  position: relative;
  /*padding: 27px 0 20px 0;*/
	padding: 58px 0 40px 0;
}
/*
body header#nav::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -3px;
  width: 100%;
  height: 3px;
  background-color: #0ca9aa;
  z-index: 0;
}*/
#nav .flex-nav .logo {
  padding: 0;
}
#nav nav ul {
	margin-right:-15px;
}
#nav nav ul li a {
  position: relative;
  font-size: 14px;
  font-family: $open_sans_semi_bold;
  color: #323838;
  text-transform: inherit;
  line-height: 14px;
  padding: 5px 0;
}
#nav nav ul li a:hover {
  color: inherit;
}
#nav nav ul li {
  position: relative;
  padding: 0 15px;
	white-space: nowrap;
}
#nav nav ul li a::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 0;
  height: 1px;
  background-color: #323838;
  transform: translateX(-50%);
  transition: width 0.25s;
}
#nav nav ul li:hover a::after,
#nav nav ul li.on a::after {
  width: 100%;
}

//Fixed-header
body .fixed-header {position:static;}

// Footer
#footer {background-color:black; margin-bottom:0;}
footer {}

.footer_logo svg, .wrapp-flex-ul-footer-logo svg {
	max-width: 405px;
	fill: #ffffff!important;
}
.wrapp-flex-ul-footer-logo svg {
	max-width: 100%;
}

header svg {
	max-width:100%;
	fill: #676767;
}

// flexSlider or separate banner partial somewhere?
#banner-wrap {
    min-height:788px;
    background-color:#666;
    position:relative;
}
#banner-wrap ul > li{
    /* funky text stylng that can be seen on any colour background */

    font-family:Georgia, serif;
    font-size:200%;
    color: #000;
    text-shadow:
        .2em .2em .5em #000,
        -1px -1px 0 #fff,
        1px -1px 0 #fff,
        -1px 1px 0 #fff,
        1px 1px 0 #fff;
    padding-top:30px;

}

#home #main {
  position: relative;
  background-color: #fff;
  //border-top: 3px solid #0ca9aa;
}
#home #main::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 3px;
  width: 100%;
  background-color: $navy;
  z-index: 1;
}

.title-desc {
  max-width: 600px;
  font-family: $open_sans_light;
  font-size: 16px;
  letter-spacing: 0.6px;
  text-align: left;
  color: #4a4a4a;
}
.title-wrap {
  margin-bottom: 50px;
}
.title-wrap h1,
.title-wrap h2,
.title-wrap h3,
.title-wrap h4 {
  font-size: 36px;
  font-family: $open_sans_light;
  font-weight: normal;
  letter-spacing: 1.6px;
  text-align: left;
  line-height: 1.4;
  color: $bright-orange;
  margin: 0;
  text-transform: capitalize;
  //margin-bottom: 50px;
}
.title-wrap h1 span,
.title-wrap h2 span,
.title-wrap h3 span,
.title-wrap h4 span {
  color: #525a5a;
}

.orange_color {
  color: $bright-orange;
}

.text_column_2 {
  column-count: 2;
  column-gap: 30px;
}
.text_column_3 {
  column-count: 3;
  column-gap: 30px;
}

.block {
  /*padding: 45px 0;*/
  padding: 60px 0;
}
.block_gray {
  background-color: #f7f7f7;
}
.block img {
  display: block;
  max-width: 100%;
  margin-bottom: 25px;
}

.wrapp-btn-more {
  display: flex;
  justify-content: flex-end;
  height: 71px;
  margin-top: 30px;
  margin-bottom: 30px;
}
.wrapp-btn-more-m0 {
  margin: 0;
}
.btn-more {
  height: 100%;
  display: flex;
  overflow: hidden;
  padding:0;
}
.btn-more:hover .btn-more_text {
  width: 132px;
}
.btn-more:hover .btn-more_arrow {
  background-color: $dull-orange;
}
.btn-more_arrow {
  position: relative;
  display: block;
  height: 71px;
  width: 71px;
  background-color: $orange;
  transition: all 0.25s;
  z-index: 1;
}
.btn-more_arrow::after {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  width: 30px;
  height: 22px;
  transform: translate(-50%, -50%);
  background-image: url('../img/svg-ico/arrow-r.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
.btn-more-mail .btn-more_arrow::after {
  background-image: url("../img/svg-ico/e-mail-envelope.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 28px;
  height: 18px;
}
.btn-more_text {
  display: block;
  position: relative;
  height: 100%;
  width: 0;
  background-color: $orange;
  line-height: 71px;
  font-size: 14px;
  letter-spacing: 0.6px;
  text-align: center;
  color: #ffffff;
  transition: all 0.25s;
}
.btn-more-arr-text {
  position: relative;
  font-size: 13px;
  letter-spacing: 0.5px;
  text-align: center;
  color: #323838;
  overflow: inherit;
}
.btn-more-arr-text .btn-more_arrow {
  background-image: none;
}
.btn-more-arrow-left {
  border-right: 1px solid #ffffff;
}
.btn-more-arrow-left .btn-more_arrow::after {
  background-image: url('../img/svg-ico/arrow-l.svg');
}
.btn-more-arrow-right .btn-more_arrow::after {
  background-image: url('../img/svg-ico/arrow-l.svg');
  transform: translate(-50%, -50%) rotate(180deg);
}
.btn-more_nav_text {
  position: absolute;
  left: 0;
  bottom: -25px;
  text-align: center;
  display: block;
  width: 100%;
  opacity: 0;
  visibility: hidden;
  transition: all 0.25s;
}
.btn-more-arr-text:hover .btn-more_nav_text {
  opacity: 1;
  visibility: visible;
  color: #323838;
}

.wrapp_btn_border_top {
  border-top: 1px solid #cac9c9;
  margin-top: 57px;
}

.text_fz16, .text_fz16 p {font-family: $open_sans_light;font-size: 16px;color: #4a4a4a;letter-spacing: 0.6px;}
.text_fz18, .text_fz18 p {font-family: $open_sans_light;font-size: 18px;color: #4a4a4a;letter-spacing: 0.6px;}
.text_fz20, .text_fz20 p {font-family: $open_sans_light;font-size: 20px;color: #4a4a4a;letter-spacing: 0.6px;}
.text_fz22, .text_fz22 p {font-family: $open_sans_light;font-size: 22px;color: #323838;letter-spacing: 1.3px;line-height: 1.5;}
.text_fz24, .text_fz24 p {font-family: $open_sans_light;font-size: 24px;color: #323838;letter-spacing: 1.3px;line-height: 1.4;}
.text_fz26, .text_fz26 p {font-family: $open_sans_light;font-size: 26px;color: #323838;letter-spacing: 1.3px;line-height: 1.35;}
.text_fz28, .text_fz28 p {font-family: $open_sans_light;font-size: 28px;color: #323838;letter-spacing: 1.3px;line-height: 1.35;}

.text_fz16 {margin-bottom: 30px;}
.text_fz18 {margin-bottom: 30px;}
.text_fz20 {margin-bottom: 30px;}
.text_fz22 {margin-bottom: 30px;}
.text_fz24 {margin-bottom: 30px;}
.text_fz26 {margin-bottom: 35px;}
.text_fz28 {margin-bottom: 40px;}

.text_fz16 h3,
.text_fz16 h4 {
  font-family: $open_sans_semi_bold;
  font-size: 17px;
  letter-spacing: 0.5px;
  text-align: left;
  color: #525a5a;
  text-transform: inherit;
  margin: 0;
  margin-bottom: 5px;
}

.text_lh, .text_lh p {
  margin: 0;
  font-size: 15px;
  font-family: $open_sans_light;
  line-height: 1.73;
  letter-spacing: 0.8px;
  text-align: left;
  color: #4a4a4a;
}
.text_lh {margin-bottom: 20px;}
.text_lh a {
  text-decoration: underline;
}
.text_lh a:hover {text-decoration: none;color: inherit;}

.logo_list {
  list-style: none;
  margin: 0;
  padding: 0;
  margin-bottom: 25px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
}
.align-right {
  display: flex;
  justify-content: flex-end;
}
.align-center {
  display: flex;
  justify-content: center;
}
.logo_item {
  position: relative;
  padding: 0 17px;
}
.logo_item img {
  max-width: 100%;
}

.carousel a[href^='#carousel-button'] {
  width: 71px;
  height: 71px;
  background: $orange;
  margin: 0;
  margin-bottom: 5px;
}
.carousel a[href^='#carousel-button']:hover {
  background-color: $dull-orange;
}
.carousel a[href^='#carousel-button']::after {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  right: inherit;
  bottom: inherit;
  background-repeat: no-repeat;
  background-position: center;
}
.carousel a[href^='#carousel-button-prev']::after {
  content: "";
  background-image: url("../img/svg-ico/arrow-nav.svg");
}
.carousel a[href^='#carousel-button-next']::after {
  content: "";
  background-image: url("../img/svg-ico/arrow-nav-right.svg");
}

.title-wrap-link {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.title-link {
  font-size: 14px;
  letter-spacing: 0.5px;
  text-align: left;
  color: #9b9b9b;
  padding-right: 30px;
  position: relative;
  margin-left: 10px;
}
.title-link:hover {
  color: #323838;
}
.title-link:hover::after {
  background-image: url('../img/svg-ico/small-arrow-right-grey-hover.svg');
}
.title-link::after {
  content: "";
  position: absolute;
  right: 0;
  top: 50%;
  width: 19px;
  height: 8px;
  transform: translateY(-50%);
  background-image: url('../img/svg-ico/small-arrow-right-grey.svg');
  background-repeat: no-repeat;
  background-position: center;
}

.row_padding {
  padding: 0 16px;
}


.flex-parent.row {
  margin-left: -16px;
  margin-right: -16px;
  padding-top: 0;
  padding-bottom: 0;
  //width: 100%;
}
/**/
.flex-parent.row-inn {
  margin-left: -16px;
  margin-right: -16px;
  padding: 0;
  width: 100%;
}
.flex-parent.row-m {
  margin-left: -16px;
  margin-right: -16px;
}
.col-6 {
  width: 50%;
  padding: 0 16px;
}
.col-12 {
  width: 100%;
  padding: 0 16px;
}
.col-4 {
	width: 33.333%;
	padding: 0 16px;
}
.col-8 {
	width: 66.666%;
	padding: 0 16px;
}
.col-5 {
	width: 41.666%;
	padding: 0 16px;
}
.col-7 {
	width: 58.333%;
	padding: 0 16px;
}


.flexSlider-dots {display:none;}

.banner-text {position:absolute; bottom:0; right:0; left:0; padding:20px 0; background:rgba(0,0,0,0.5);}

/* example custom animations */
.flexSlider .current [class*="animate-in-"].animate-custom-a {
    -webkit-transition-delay: 1000ms;
    transition-delay: 1000ms;
    color:#990000;
}
.flexSlider .current [class*="animate-in-"].animate-custom-b {
    -webkit-transition-delay: 400ms;
    transition-delay: 400ms;
    -webkit-transform:rotate(360deg);
    transform:rotate(360deg);
}
.flexSlider [class*="animate-in-"].animate-custom-c {
    font-size:0%;
}
.flexSlider .current [class*="animate-in-"].animate-custom-c {
    -webkit-transition-delay: 600ms;
    transition-delay: 600ms;
    color:#333;
    font-size:100%;
}

.bounds_container {
  padding: 0 16px;
}

/* Begin pagination */
.pagination,
.pagedResults{
  position: relative;
  list-style: none;
  padding: 0;
  margin: 0;
  text-align: right;
  padding-right: 100px;
  li {
    display: inline-block;
    text-decoration: underline;
    a, span {
      padding: 5px 8px;
      display: block;
      font-size: 14px;
      line-height: 1;
      text-align: left;
      color: #3d505a;
    }
    &:hover {
      text-decoration: none;
    }
    &.is-active,
    &.active{
      a, span {
        color: #252525;
        font-family: $open_sans_bold;
      }
    }
  }
}
/* End pagination */

/* Begin header */
.wrapp-flex-ul {
  display: flex;
  justify-content: flex-end;
  padding-right: 0px;
}
.flex-nav .logo {
  height: inherit;
}
.flex-nav .logo a {
  display: block;
/* height: 89px;*/
  position: relative;
}
.flex-nav .logo a img {
  /*max-width: 100%;*/
  max-width: 330px;
  display: block;
  max-height: 100%;
}
.flex-nav .logo a img.logo-img-mobile {
  display: none;
}
.wrapp-flex-ul-footer {
  display: none;
}
.wrapp-flex-ul-close {
  display: none;
}
/* End header */

.stickyFootWrap {
  /*max-width: 1440px;*/
  margin: 0 auto;
  overflow-x: hidden;
}
body .bounds {
  max-width: 1260px;
}

/* about us columns */
.twocolumntext {
	-webkit-columns: 2 200px;
	-moz-columns: 2 200px;
	columns: 2 200px;
	-webkit-column-gap: 2em;
	-moz-column-gap: 2em;
	column-gap: 2em;
	padding:0 16px;
}
/* end about us columns */
/* Begin latest news */
.latest_news {
  background-color: $navy;
  color: #fff;
  overflow: hidden;
}

.latest_news .bounds {
  position: relative;
}
.latest_news-title {
  font-family: $open_sans_semi_bold;
  font-size: 17px;
  line-height: normal;
  letter-spacing: 0.8px;
  text-align: left;
  text-transform: uppercase;
  color: #fff;
  padding: 14px 0 16px;
}
.latest_news-name {
  font-size: 17px;
  letter-spacing: 0.8px;
  text-align: left;
  color: #fff;
  /*white-space: nowrap;
  text-overflow: ellipsis;*/
  overflow: hidden;
  padding-right: 220px;
  padding-left: 220px;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
}
.latest_news-read_more {
  display: block;
  position: absolute;
  right: -828px;
  top: 0;
  width: 1000px;
  height: 100%;
  background-color: $dark-navy;
  font-size: 14px;
  font-family: $open_sans_semi_bold;
  letter-spacing: 0.5px;
  text-align: left;
  color: #fff;
  transition: all 0.4s;
  z-index: 2;
}
.latest_news-read_more:hover {
	/*background-color: #077a7a;*/
	background-color: $dull-orange;
}
.latest_news-read_more::after,
.latest_news-read_more::before {
	content: "";
	position: absolute;
	left: 31px;
	top: 50%;
	width: 14px;
	height: 2px;
	margin-top: -1px;
	background-color: $bright-orange;
}
.latest_news-read_more:hover::after,
.latest_news-read_more:hover::before {
	background-color: $white;
}
.latest_news-read_more::before {
  transform: rotate(90deg);
}
.latest_news-read_more-text {
  font-size: 14px;
  font-family: $open_sans_semi_bold;
  letter-spacing: 0.5px;
  text-align: left;
  color: #fff;
  position: absolute;
  left: 77px;
  top: 50%;
  transform: translateY(-50%);
}

/* End latest news */

/* Begin page banner */
.page_banner {
  position: relative;
  height: 700px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  overflow: hidden;
}
.page_banner_inner {
  position: relative;
}
.page_banner_inner::after {
  content: "";
  position: absolute;
  right: -828px;
  top: 0;
  height: 100%;
  width: 1000px;
  opacity: 0.27;
  background-color: #ffffff;
}
.page_banner .flex-parent {
  position: relative;
  height: 100%;
}
.page_banner-info {
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
  width: 588px;
  padding-bottom: 85px;
  z-index: 3;
}
.page_banner-info_inner {
  background-color: #fff;
  display: block;
  height: 100%;
  width: 100%;
  padding: 50px 30px 65px 50px;
}
.page_banner .title-wrap {
  margin-bottom: 25px;
}
.page_banner-info_desc {
  font-size: 16px;
  font-family: $open_sans_light;
  letter-spacing: 0.6px;
  text-align: left;
  color: #4a4a4a;
}

.page_banner-info_link {
  position: absolute;
  right: 0;
  bottom: 0;
  display: block;
  width: 85px;
  height: 85px;
  z-index: 1;
  transition: 0.2s all 0.3s
}
.page_banner-info_link_arrow {
  position: relative;
  width: 85px;
  height: 100%;
  background-color: $orange;
  overflow: hidden;
  transition: 0.2s all 0.3s
}
.page_banner-info_link_arrow::after {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  width: 22px;
  height: 30px;
  transform: translate(-50%, -50%) rotate(90deg);
  background-image: url(../img/svg-ico/arrow-top.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
.page_banner-info_link_text {
  position: absolute;
  right: 0;
  top: 0;
  display: block;
  //width: 156px;
  width: 0;
  height: 100%;
  line-height: 85px;
  background-color: $orange;
  font-size: 16px;
  letter-spacing: 0.7px;
  text-align: center;
  color: #ffffff;
  transition: all 0.2s;
}
.page_banner-info_link_text_inner {
  opacity: 0;
  white-space: nowrap;
  padding-left: 20px;
  transition: 0.5s all 0.4s;
}
.page_banner-info_link:hover .page_banner-info_link_text {
  width: 156px;
  right: -156px;
}
.page_banner-info_link:hover {
  right: 156px;
}
.page_banner-info_link:not(:hover) {
  transition-delay: 0.2s;
}
.page_banner-info_link:not(:hover) .page_banner-info_link_arrow {
  transition-delay: 0.2s;
}
.page_banner-info_link:hover .page_banner-info_link_arrow {
  background-color: $dull-orange;
  overflow: inherit;
}
.page_banner-info_link:hover .page_banner-info_link_text_inner {
  opacity: 1;
  padding-left: 0;
}
.page_banner-info_link:not(:hover) .page_banner-info_link_text_inner {
  transition: all 0.2s;
}

.bottom_link {
  position: absolute;
  left: 50%;
  bottom: 21px;
  width: 85px;
  height: 85px;
  transform: translateX(-50%);
  z-index: 11;
  transition: all 0.3s;
}
.bottom_link:hover {
  bottom: 0px;
}
.bottom_link:hover .bottom_link_arrow {
  background-color: $dull-orange;
}
.bottom_link:hover .bottom_link_text {
  top: -100%;
  z-index: 2;
}
.bottom_link:not(:hover) {
  transition-delay: 0.2s;
}
.bottom_link:not(:hover) .bottom_link_arrow {
  transition-delay: 0.2s;
}
.bottom_link:not(:hover) .bottom_link_text {
  transition-delay: 0.2s;
}
.bottom_link:not(:hover) .bottom_link_text_inner {
  opacity: 0;
  top: 40%;
  transition: all 0.2s;
}
.bottom_link:hover .bottom_link_text_inner {
  opacity: 1;
  top: 50%;
}
.bottom_link_arrow {
  position: absolute;
  left: 0;
  top: 0;
  display: block;
  width: 100%;
  height: 85px;
  background-color: $orange;
  z-index: 2;
  transition: all 0.3s;
}
.bottom_link_arrow::after {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  width: 22px;
  height: 30px;
  transform: translate(-50%, -50%) rotate(180deg);
  background-image: url(../img/svg-ico/arrow-top.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
.bottom_link_text {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 85px;
  background-color: $orange;
  font-size: 14px;
  letter-spacing: 0.6px;
  text-align: center;
  color: #ffffff;
  z-index: 1;
  transition: all 0.3s;
}
.bottom_link_text_inner {
  position: absolute;
  left: 50%;
  top: 60%;
  transform: translate(-50%, -50%);
  width: 100%;
  text-align: center;
  padding: 0 10px;
  line-height: 1.42;
  opacity: 0;
  transition: 0.2s all 0.3s;
}


/* End page banner */

/* Begin advantages */
.advantages {
  /*background-color: #f7f7f7;*/
  background-color: $navy;
  min-height: 360px;
  overflow: hidden;
}
.advantages-list,
.advantages-desc {
  min-height: 361px;
  height: 100%;
}
.advantages .flex-parent {
  align-items: center;
  padding-top: 0;
  padding-bottom: 0;
  align-content: center;
}
.advantages .advantages-list {
  position: relative;
  width: 51%;
  min-height: 100px;
  list-style: none;
  margin: 0 0 0 -5px;
  padding: 0 50px 0 0;
  justify-content: space-between;
  align-content: center;
  align-items: center;
	color: $white;
}
.advantages-item {
  min-width: 85px;
  padding: 0 5px;
}
.advantages-item_img {
  position: relative;
  height: 84px;
  width: 100%;
  margin-bottom: 15px;
}
.advantages-item_img img {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  max-width: 100%;
  max-height: 100%;
  display: block;
}
.advantages-item_title {
  display: block;
  font-family: $open_sans_semi_bold;
  font-size: 14.3px;
  letter-spacing: 0.5px;
  text-align: center;
}
.advantages-desc {
  position: relative;
  width: 49%;
  /*background-color: #edeeee;*/
  background-color: $dark-navy;
  padding-left: 67px;
  padding-top: 86px;
  padding-right: 30px;
}
.advantages-desc::after {
  content: "";
  position: absolute;
  right: -1000px;
  top: 0;
  height: 100%;
  width: 1000px;
  /*background-color: #edeeee;*/
  background-color: $dark-navy;
  z-index: 0;
}
.advantages-desc-inner {
  position: relative;
  z-index: 1;
}
.advantages-desc,
.advantages-desc p {
  font-size: 22px;
  font-family: $open_sans_light;
  letter-spacing: 1px;
  text-align: left;
  color: $white;
  margin: 0;
  padding-bottom: 30px;
  line-height: 1.42;
}

/* End advantages */

/* Begin our services */
.our_services {
  padding: 50px 16px 35px;
  overflow: hidden;
}
.our_services .our_services-list {
  padding-top: 0;
  padding-bottom: 0;
  margin-left: -37px;
  margin-right: -37px;
}
.our_services-item {
  position: relative;
  margin-bottom: 35px;
  padding: 0 21px;
  width: 25%;
}
.our_services-border {
  border: 1px solid #cac9c9;
  height: 281px;
  overflow: hidden;
}
.our_services-img {
  position: relative;
  display: block;
  height: 210px;
  width: 100%;
  overflow: hidden;
  transition: all 0.25s;
}
.our_services-img::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  opacity: 0;
  height: 105px;
  width: 100%;
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), #000000);
  transition: all 0.25s;
}

.our_services-img:hover::after {
  bottom: 0;
  opacity: 0.38;
}
.our_services-desc {
  height: 60px;
  font-size: 14px;
  font-family: $open_sans_light;
  letter-spacing: 0.5px;
  text-align: left;
  color: #4a4a4a;
  line-height: 1.42;
  padding: 0 21px 0 25px;
  overflow: hidden;
  transition: all 0.25s;
}

.our_services-item.hover .our_services-img {
  height: 135px;
}
.our_services-item.hover .our_services-desc {
  height: 60px;
}

.our_services-img:hover .our_services-img-inner {
  transform: scale(1.05);
}
.our_services-img-inner {
  height: 210px;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  transition: all 0.4s;
}
.our_services-title {
  display: block;
  height: 70px;
  position: relative;
  font-size: 14px;
  font-family: $open_sans_semi_bold;
  letter-spacing: 0.5px;
  text-align: left;
  color: $bright-orange;
  overflow: hidden;
}
.our_services-title:hover {
  color: $bright-orange;
}
.our_services-title span {
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  padding: 0 21px 0 25px;
  line-height: 1.4;
  max-height: 60px;
  overflow: hidden;
}
.wrapp_our_services-more {
  display: flex;
  justify-content: flex-end;
  height: 71px;
}
.our_services-more {
  height: 100%;
  display: flex;
  overflow: hidden;
}
.our_services-more:hover .our_services-more_text {
  width: 132px;
}
.our_services-more:hover .our_services-more_arrow {
  background-color: $dull-orange;
}
.our_services-more_arrow {
  display: block;
  height: 100%;
  width: 71px;
  background-color: $orange;
  background-image: url("../img/arrow-r.png");
  background-repeat: no-repeat;
  background-position: center;
  transition: all 0.25s;
}
.our_services-more_text {
  display: block;
  position: relative;
  height: 100%;
  width: 0;
  background-color: $orange;
  line-height: 71px;
  font-size: 14px;
  letter-spacing: 0.6px;
  text-align: center;
  color: #ffffff;
  transition: all 0.25s;
}

.wrapp_services {
	ul {list-style-type: none; margin:0;}
	li {margin:0 0 1em;}
	li:before {
		content: " ";
		display: inline-block;
		width: 10px;
		height: 10px;
		background-color: $orange;
		border-radius: 50%;
		vertical-align: middle;
		margin-top: -5px;
		margin-left: -35px;
		margin-right: 20px;
	}
}

/* End our services */

.hr hr {
  margin: 0;
  border-top: solid 2px #cac9c9;
}
.hr_m {
  margin: 50px 0;
}

/* Begin our core values */
.core_values {
  padding: 40px 0 25px;
}
/* End our core values */

.gray_block {
  background-color: #f7f7f7;
  padding-top: 50px;
  overflow: hidden;
}

/* Begin social media */
.social_media_list {
  list-style: none;
  margin: 0;
  padding: 0;
}
.social_media_item {
  position: relative;
  padding-left: 48px;
  padding-bottom: 20px;
  border-bottom: solid 1px #cac9c9;
  margin-bottom: 25px;
}
.social_media_item:last-child {
  margin-bottom: 0;
  border-bottom: none;
}
.social_media_item::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 28px;
  height: 28px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
.social_media_item.twitter::after {
  background-image: url('../img/svg-ico/twitter.svg');
}
.social_media_header {
  display: flex;
  justify-content: space-between;
}
.social_media_title {
  display: block;
  font-size: 14px;
  font-family: $open_sans_bold;
  line-height: 1.29;
  letter-spacing: 0.4px;
  text-align: left;
  color: #525a5a;
  text-decoration: underline;
  margin-bottom: 8px;
}
.social_media_title:hover {
  color: $orange;
}
.social_media_content,
.social_media_content p {
  font-family: $open_sans_light;
  font-size: 14px;
  letter-spacing: 0.5px;
  text-align: left;
  color: #4a4a4a;
  margin: 0;
  line-height: 1.42;
}
.social_media_date {
  font-size: 14px;
  letter-spacing: 0.5px;
  text-align: left;
  color: #9b9b9b;
  margin-bottom: 8px;
}
/* End social media */

/* Begin latest news */
.news_list {
  margin: 0;
  padding: 0;
  list-style: none;
}
.news_item {
  position: relative;
  margin-bottom: 60px;
  height: 284px;
}

.carusel_latest_news .news_item {
	height: 254px;
}
/*.news_item:last-child .news_item_inner {
  border-bottom: none;
}*/
.news_item_inner {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  padding-bottom: 30px;
  border-bottom: 1px solid #cac9c9;
}
.news_img {
  position: relative;
  display: block;
  //width: 286px;
  width: 49%;
  height: 100%;
  border: 1px solid #cac9c9;
  overflow: hidden;
}
.news_img:hover .news_img-inner {
  transform: scale(1.05);
}
.news_img-inner {
  display: block;
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  transition: all 0.4s;
}
.news_img::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  opacity: 0;
  height: 105px;
  width: 100%;
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), #000000);
  transition: all 0.25s;
}
.news_img:hover::after {
  bottom: 0;
  opacity: 0.38;
}
.news_info {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  margin-top: -15px;
  padding: 18px;
  background-color: #fff;
  //width: 350px;
  width: 60%;
  height: 168px;
  border: 1px solid #cac9c9;
  overflow: hidden;
}
.news_date {
  font-size: 14px;
  letter-spacing: 0.5px;
  text-align: left;
  color: #9b9b9b;
  margin-bottom: 12px;
  line-height: 1.42;
}
.single_news_date {
  margin-bottom: 22px;
}
.carusel_latest_news {
  width: 50%!important;
}
.carousel.wrapp_carusel_latest_news a[href^='#carousel-button']:first-child {
  border-right: 1px solid #fff;
}
.carousel.wrapp_carusel_latest_news a[href^='#carousel-button']:nth-child(2) {
  border-left: 1px solid #fff;
}
.news_title {
  display: block;
  font-family: $open_sans_semi_bold;
  font-size: 17px;
  letter-spacing: 0.5px;
  text-align: left;
  color: #525a5a;
  line-height: 1.42;
  margin-bottom: 8px;
  height: 72px;
  overflow: hidden;
}
.news_title:hover {
  color: $orange;
}
.news_more {
  font-size: 14px;
  letter-spacing: 0.5px;
  text-align: left;
  line-height: 1.42;
  color: #9b9b9b;
  padding-right: 25px;
  position: relative;
}
.news_more:hover {
  color: #323838;
}
.news_more:hover::after {
  background-image: url('../img/svg-ico/small-arrow-right-orange.svg');
}
.news_more::after {
  content: "";
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 18px;
  height: 10px;
  background-image: url('../img/svg-ico/small-arrow-right-grey-light.svg');
  background-repeat: no-repeat;
  background-size: contain;
}
.home_w2 {
  overflow: hidden;
}

.carousel-lastet_news {
  display: none;
}

.carusel-news_list .news_item {
  height: inherit;
}
.carusel-news_list .news_item_inner {
  border: none;
}
.carusel-news_list .news_img {
  width: 100%;
  height: 210px;
}
.carusel-news_list .news_item_inner {
  padding: 0;
  margin: 0;
}
.carusel-news_list .news_info {
  position: relative;
  right: inherit;
  top: inherit;
  width: 100%;
  height: initial;
}

/* End lastet news */

/* Begin contact */
.contact_form {
  padding-left: 67px;
  transition: all 0.25s;
}
.contact_form_item_m0 textarea {
  margin: 0;
}

.wrapp_map {
  padding-top: 0;
}
.map {
  display: block;
  height: 472px;
  border: 2px solid #CAC9C9;
}

/* End contact */

/* Begin news */
.wrapp_carusel_latest_news .carusel_latest_news .news_item {
  padding-right: 4px;
}
/* End news */

/* Begin our people item */
.people_item {
  position: relative;
  margin-bottom: 38px;
}
.people_item_inner {
  position: relative;
  height: 260px;
  width: 100%;
  overflow: hidden;
  border: solid 1px #cac9c9;
  display: flex;
  flex-wrap: wrap;
}
.people_item_img {
  position: relative;
  display: block;
  width: 44%;
  height: 100%;
  overflow: hidden;
}
.people_item_img::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  opacity: 0;
  height: 105px;
  width: 100%;
  background-image: linear-gradient(to bottom, rgba(255,255,255,0), #000);
  transition: all 0.25s;
}
.people_item_img:hover::after {
  bottom: 0;
  opacity: 0.38;
}
.people_item_img img {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  margin: 0;
  max-width: 100%;
  min-height: 100%;
  min-width: 100%;
	transition: all 0.5s;
}
.people_item_img:hover img {
	max-width: 110%;
	min-width: 110%;
	min-height: 110%;
}
.people_item_info {
  width: 56%;
  padding: 30px 25px 25px 30px;
}
.people_item_name {
  display: block;
  font-family: $open_sans_semi_bold;
  font-size: 14px;
  letter-spacing: 0.5px;
  text-align: left;
  color: $orange;
  line-height: 1;
  transition: all 0.25s;
}
.people_item_job {
  font-size: 14px;
  letter-spacing: 0.5px;
  text-align: left;
  color: #4a4a4a;
  margin-bottom: 10px;
}
.people_item_desc {
  font-family: $open_sans_light;
  font-size: 14px;
  letter-spacing: 0.5px;
  text-align: left;
  color: #4a4a4a;
  line-height: 1.42;
  max-height: 152px;
  overflow: hidden;
}

.single-people-img{
	width:100%;
}
.boxed-title {
	border-bottom:1px solid #cccccc;
	margin-bottom: 30px;
}
.boxed-title span {
	display: inline-block;
	position: relative;
	width: 70px;
	height: 70px;
	background: $orange;
	vertical-align: middle;
	margin-right: 15px;
}
.boxed-title span::after {
	content: " ";
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	margin: auto;
	background-image: url(../img/svg-ico/avatar.svg);
	background-repeat: no-repeat;
	background-position: center;
	background-size: contain;
	width: 22px;
	height: 22px;
}
.boxed-title .text_fz28 {
	margin: 0;
	display: inline-block;
	vertical-align: middle;
}


.accordeon_woodfood_people {
  display: none;
  .people_item_name {
    margin-bottom: 5px;
  }
  .people_item_job {
    margin-bottom: 0;
  }
  .accordeon_woodfood_trigger {
    .accordeon_woodfood_trigger_btn {
      width: 62px;
    }
  }
  .accordeon_woodfood_item {
    &.active {
      .accordeon_woodfood_trigger {
        .people_item_name, .people_item_job {
          color: #fff;
        }
      }
    }
  }
}

/* End our people item */

/* Begin services item */
.block.wrapp_services {
  padding-bottom: 0;
}
.services_item {
  position: relative;
  margin-bottom: 38px;
}
.services_item_inner {
  position: relative;
  height: 220px;
  width: 100%;
  overflow: hidden;
  border: solid 1px #cac9c9;
  display: flex;
  flex-wrap: wrap;
}
.services_item_img {
  position: relative;
  display: block;
  width: 51%;
  height: 100%;
  overflow: hidden;
}
.services_item_img::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  opacity: 0;
  height: 105px;
  width: 100%;
  background-image: linear-gradient(to bottom, rgba(255,255,255,0), #000);
  transition: all 0.25s;
}
.services_item_img:hover::after {
  bottom: 0;
  opacity: 0.38;
}
.services_item_img img {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  margin: 0;
  max-width: inherit;
  min-height: 100%;
  min-width: 100%;
}
.services_item_info {
  width: 49%;
  padding: 35px 25px 25px 30px;
}
.services_item_name {
  display: block;
  font-family: $open_sans_semi_bold;
  font-size: 14px;
  letter-spacing: 0.5px;
  text-align: left;
  color: $bright-orange;
  line-height: 1;
  margin-bottom: 19px;
  transition: all 0.25s;
}
.services_item_desc {
  font-family: $open_sans_light;
  font-size: 14px;
  letter-spacing: 0.5px;
  text-align: left;
  color: #4a4a4a;
  line-height: 1.42;
  max-height: 114px;
  overflow: hidden;
}
.accordeon_woodfood_people {
  display: none;
  .services_item_name {
    margin-bottom: 5px;
  }
  .services_item_job {
    margin-bottom: 0;
  }
  .accordeon_woodfood_trigger {
    .accordeon_woodfood_trigger_btn {
      width: 62px;
    }
  }
  .accordeon_woodfood_item {
    &.active {
      .accordeon_woodfood_trigger {
        .services_item_name, .services_item_job {
          color: #fff;
        }
      }
    }
  }
}
/* End services item */

/* Begin accordeon woodfood */
.accordeon_woodfood_list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.accordeon_woodfood_item {
  position: relative;
  &.active {
    .accordeon_woodfood_trigger {
      background-color: $navy;
      color: #ffffff;
      font-family: $open_sans;
      .accordeon_woodfood_trigger_btn {
        background-color: desaturate(lighten($navy,25%),40%);
        &::after {
          transform: translate(-50%, -50%) rotate(90deg);
        }
      }
    }
  }
}

.accordeon_woodfood_trigger {
  position: relative;
  display: block;
  font-family: $open_sans_light;
  font-size: 18px;
  letter-spacing: 0.2px;
  text-align: left;
  color: #232727;
  line-height: 1;
  padding-bottom: 15px;
  padding-top: 15px;
  padding-left: 23px;
  padding-right: 65px;
  margin-bottom: 2px;
  background-color: #e7e6e6;
  overflow: none;
  &:hover {
    color: #232727;
  }
  .accordeon_woodfood_trigger_btn {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 50px;
    display: block;
    z-index: 1;
    background-color: $orange;
    &::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 17px;
      height: 1px;
      background: #ffffff;
      z-index: 1;
    }
    &::after {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 1px;
      height: 17px;
      background: #ffffff;
      z-index: 1;
      transition: transform 0.2s ;
    }
  }
}

.accordeon_woodfood_inner {
  display: none;
  position: relative;
  padding: 23px;
  color: #000;
  font-family: $open_sans_light;
  font-size: 14px;
  border-left: 1px solid #eaebe8;
  border-right: 1px solid #eaebe8;
  letter-spacing: 0.5px;
  text-align: left;
  color: #4a4a4a;
  line-height: 1.42;
}
.accordeon_woodfood_inner p {
  letter-spacing: 0.5px;
  text-align: left;
  color: #4a4a4a;
  font-size: 14px;
  font-family: $open_sans_light;
  line-height: 1.42;
}
.accordeon_woodfood_item {
  &:last-child {
    .accordeon_woodfood_inner {
      border-bottom: 1px solid #eaebe8;
    }
  }
}

/* End accordeon woodfood */


.title-wrap.mobile-visible {
  display: none;
}
.last_block_main {
  padding-bottom: 85px;
}
.wrapper_news .news_item {
  height: 254px;
}

@import 'media-queries/media-queries';
