// style.scss

/*!
 *  Stunnplate style
 *  Version: 2.0
 */
/*
 *  Notes:
 *  Main SCSS file to include entire library followed by custom styles.
 * 
 */

@import 'variables';

/*-------------------------------------------------------*/
/*- HELPERS                                             -*/
/*-------------------------------------------------------*/
/*MG helpers have to go first! */
@import 'helpers/fonts';
@import 'helpers/mixins';
@import 'helpers/placeholders';
@import 'helpers/include-media';


/*-------------------------------------------------------*/
/*- BASE                                                -*/
/*-------------------------------------------------------*/

/* Normalise */
@import 'base/normalize';

/* Core */
@import 'base/default';

/*-------------------------------------------------------*/
/*- COMMON ELEMENTS                                     -*/
/*-------------------------------------------------------*/
@import 'common';

/*-------------------------------------------------------*/
/*- STUNNPLATE LIBRARY MODULES                          -*/
/*-------------------------------------------------------*/
/* Stunn Modules */
@import '../modules/simple-fixed-header/simple-fixed-header';
/*@!import '../modules/fixedHeader/fixedheader';*/
/*@!import '../modules/layout/layout';*/
@import '../modules/layout/flexlayout';
/*@!import '../modules/nav/nav';*/
@import '../modules/nav/flex-nav';
@import '../modules/tabbed/tabbed';
@import '../modules/modal/modal';
@import '../modules/carousel/carousel';
/*@!import '../modules/almostInView/almostInView';*/
@import '../modules/maps/maps';
@import '../modules/flexSlider/flexSlider';


/* Vendor Modules */
/* No vendor CSS as yet */


/*-------------------------------------------------------*/
/*- COMPONENTS                                          -*/
/*-------------------------------------------------------*/
@import 'components/icons';


/*-------------------------------------------------------*/
/*- PAGE SPECIFIC / LAYOUTS								-*/
/*-------------------------------------------------------*/
@import 'layouts/default';
@import 'layouts/footer';
@import 'layouts/stunnplate-demo';

/*-------------------------------------------------------*/
/*-     PRINT STYLES                                    -*/
/*-------------------------------------------------------*/
@import 'base/print'; //arguable whether this should go in base